import React from 'react'

const Project = ({ name, description, link, image }) => (
  <div className="projects__grid__item">
    <a href={link} alt={name}>
      <img src={image} alt={name} />
    </a>
    <strong>{name}</strong>
    <span>{description}</span>

  </div>
)

export default Project;
