import React from 'react';

import dataPartners from '../../data/partners'
import Partner from './partner'

const PartnersList = () => {
  return (
    <div className="partners__list">
      {dataPartners.map((partner, index) => (
        <Partner name={partner.name} image={partner.image} link={partner.link} key={index} />
      ))}
    </div>
  );
}

export default PartnersList;
