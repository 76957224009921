import React from 'react'

import HeroImg from './images/sections/hero'

const Hero = () => (
  <div className="hero">
    <div className="hero__content">
      <div className="hero__img"></div>

      <div className="hero__title">
        <h1>Transformando <span className="bold">ideias</span> em <span className="bold">negócios</span></h1>
      </div>

      <div className="hero__paragraph">
        Nosso propósito é convergir tecnologia, conhecimento,
        experiências e conexões para  otimizar e multiplicar
        os resultados da sua startup. Acreditamos, assim, que
        a transformação do mundo em que vivemos resulta da combinação
        da tecnologia e do empreendedorismo.
    </div>
    </div>
    <div className="hero__content">
      <HeroImg />
    </div>
  </div>
)

export default Hero