import React from 'react'

const Partner = ({ name, image, link }) => (
  <div className="partners__list__item">
    <a href={link} alt={name}>
      <img src={image} alt={name} />
    </a>
  </div>
)

export default Partner;
