import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({altName}) => {
  const data = useStaticQuery(graphql`
    query{
      logo: file(relativePath: {eq: "logo-incubatech.png"}){
        childImageSharp{
          fixed(width: 200){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.logo.childImageSharp.fixed} alt={altName} />

}

export default Image
