import faceponto from '../images/projects/faceponto.png'
import virtuous from '../images/projects/virtuous.png'
import foco from '../images/projects/foco.png'
import easyDiet from '../images/projects/easy-diet.png'
import clinicaLar from '../images/projects/clinica-lar.png'
import agenciaLar from '../images/projects/agencia-lar.png'
import roboeduc from '../images/projects/roboeduc.png'

const projects = [
  {
    name: "Faceponto",
    description: "Gerenciador de jornada de trabalho.",
    link: "https://incubatech.faceponto.com.br/",
    image: faceponto,
  },
  {
    name: "Virtuous",
    description: "Trânsito e Cidanania.",
    link: "http://virtuous.incubatech.com.br/",
    image: virtuous,
  },
  {
    name: "Foco Nordeste",
    description: "Portal de Notícias.",
    link: "https://foconordeste.com.br",
    image: foco,
  },
  {
    name: "Easy Diet",
    description: "Saúde e Nutrição.",
    link: "https://easydiet.com.br/",
    image: easyDiet,
  },
  {
    name: "Clínica lar",
    description: "Atendimento Médico.",
    link: "https://clinicalar.com.br/site",
    image: clinicaLar,
  },
  {
    name: "Agência Lar",
    description: "Assistência para idosos.",
    link: "http://agencialar.com.br/",
    image: agenciaLar,
  },
  {
    name: "Roboeduc",
    description: "Escola de Robótica e programação.",
    link: "https://www.roboeduc.com.br/",
    image: roboeduc,
  },
]

export default projects