import React from 'react'

import Project from './project'

const projectList = ({ projects }) => (
  <div className="projects__grid">
    {projects.map((item, index) => (

      <Project key={index} name={item.name} description={item.description} link={item.link} image={item.image} />

    ))}
  </div>
)

export default projectList;
