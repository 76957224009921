import Foco from '../images/partners/foco.png'
import RoboEduc from '../images/partners/roboeduc.png'
import JMT from '../images/partners/jmt.png'
import Clarear from '../images/partners/clarear.png'
import Void3D from '../images/partners/void3d.png'
import Cassio from '../images/partners/cassio.png'
import Escoces from '../images/partners/escoces.png'

const Partners = [
  {
    name: "Foco Nordeste",
    link: "http://www.foconordeste.com/",
    image: Foco,
  },
  {
    name: "RoboEduc",
    link: "http://www.roboeduc.com.br/",
    image: RoboEduc,
  },
  {
    name: "JMT Service",
    link: "http://jmtservice.com.br/",
    image: JMT,
  },
  {
    name: "Clarear Serviços",
    link: "#",
    image: Clarear,
  },
  {
    name: "Void3D",
    link: "https://void3d.com.br/",
    image: Void3D,
  },
  {
    name: "Cássio Leandro",
    link: "#",
    image: Cassio,
  },
  {
    name: "Escocês",
    link: "https://pt-br.facebook.com/barbeariadoescoces/",
    image: Escoces,
  },
]

export default Partners