import React from "react"
import { Link } from "gatsby"

import Instagram from './images/icons/instagram'
import Linkedin from './images/icons/linkedin'
import Facebook from './images/icons/facebook'
import Logo from "./image"
import "../styles/footer.scss"

const Footer = () => (
  <footer>
    <div className="logo logo-footer">
      <Link to="/">
        <Logo nameAlt={"Incubatech"} />
      </Link>
    </div>
    <div className="social-footer">
      <Facebook />
      <Instagram />
      <Linkedin />
    </div>
    <span>
      Incubatech © 2018
    </span>

  </footer>
);

export default Footer