import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query{
      logo: file(relativePath: {eq: "logo-incubatech.png"}){
        childImageSharp{
          fixed(width: 100){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.logo.childImageSharp.fixed} alt="Incubatech" />

}

export default Logo
