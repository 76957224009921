import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Facebook = () => {
  const data = useStaticQuery(graphql`
    query{
      facebook: file(relativePath: {eq: "social/facebook.png"}){
        childImageSharp{
          fixed{
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.facebook.childImageSharp.fixed} alt="Facebook" />

}

export default Facebook
