import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Logo from "./images/logo"
import Hero from "./hero"
import "../styles/header.scss"

const Header = ({ siteTitle }) => {

    const [isMenuOpen, setMenuToOpen] = useState(false)

    return (
        <header>
            <div className="menu__icon" onClick={() => setMenuToOpen(!isMenuOpen)}>
                <h5>MENU</h5>
                {isMenuOpen ? (<i className="fas fa-chevron-up"></i>) : (<i className="fas fa-chevron-down"></i>)}

            </div>

            {isMenuOpen ?
                <nav className="menu__mobile">
                    <ul>
                        <li>
                            <Link to="/#about">Sobre</Link>
                        </li>
                        <li>
                            <Link to="/#services">Serviços</Link>
                        </li>
                        <li>
                            <Link to="/#projects">Projetos</Link>
                        </li>
                        <li>
                            <Link to="/#events">Eventos</Link>
                        </li>
                        <li>
                            <Link to="/#media">Mídia</Link>
                        </li>
                        <li>
                            <Link to="/#contact">Contato</Link>
                        </li>
                    </ul>
                </nav>
                : <></>}

            <nav className="menu__desktop">
                <ul>
                    <li>
                        <Link to="/#about">Sobre</Link>
                    </li>
                    <li>
                        <Link to="/#services">Serviços</Link>
                    </li>
                    <li>
                        <Link to="/#projects">Projetos</Link>
                    </li>
                    <li>
                        <Link to="/#events">Eventos</Link>
                    </li>
                    <li>
                        <Link to="/#media">Mídia</Link>
                    </li>
                    <li>
                        <Link to="/#contact">Contato</Link>
                    </li>
                </ul>
            </nav>

            <div className="logo">
                <Link to="/">
                    <Logo />
                    <h1>Incubatech</h1>
                </Link>
            </div>

            <Hero />
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
